/*9. team_single_page */
.team_single_page {

    .doctor_profile {
        position: relative;
        max-width: 580px;

        @media (max-width:991px) {
            max-width: 450px;
            margin-bottom: 50px;
        }

        img {
            width: 100%;
            border-radius: 330px 330px 0px 0px;
        }

        .content {


            background: linear-gradient(358deg, rgba(30, 99, 255, 0.80) -1077.15%, rgba(24, 124, 255, 0.80) -690.64%, rgba(18, 143, 255, 0.80) -213.19%, rgba(12, 169, 255, 0.80) 218.79%, rgba(6, 194, 255, 0.80) 764.46%, rgba(0, 219, 255, 0.80) 1196.44%);
            filter: drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.05));
            backdrop-filter: blur(10px);
            width: 100%;
            text-align: center;
            padding: 40px;
            position: absolute;
            z-index: 1;
            left: 0;
            bottom: 0;

            @media (max-width:767px) {
                padding: 30px 20px;
            }

            h3 {
                color: #FFF;
                font-size: 80px;
                font-weight: 400;
                margin-top: -30px;
                margin-bottom: 0;

                @media (max-width:1199px) {
                    font-size: 60px;
                }

                @media (max-width:767px) {
                    font-size: 45px;
                    margin-top: -10px;
                }
            }

            span {
                color: #FFF;
                font-size: 19px;
                font-weight: 300;
                line-height: 30px;
            }



            ul {
                margin-top: 20px;

                li {
                    display: inline-block;
                    list-style: none;
                    margin: 0 5px;

                    a {
                        width: 40px;
                        height: 40px;
                        line-height: 45px;
                        text-align: center;
                        border-radius: 100px;
                        background: $white;
                        opacity: 0.8;
                        display: block;
                        color: $heading-color;

                    }
                }
            }
        }
    }

    h2 {
        font-size: 70px;
        font-weight: 400;
        margin-bottom: 25px;

        @media (max-width:1199px) {
            font-size: 50px;
        }

        @media (max-width:575px) {
            font-size: 40px;
        }
    }

    .doctor_info {
        max-width: 592px;
        margin-bottom: 50px;

        @media (max-width:1199px) {
            margin-bottom: 20px;
        }

        table {
            tr {
                margin-bottom: 25px;
                display: flex;

                @media (max-width:1199px) {
                    margin-bottom: 10px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                th {
                    color: $heading-color;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 30px;
                    width: 240px;

                    @media (max-width:1399px) {
                        width: 150px;
                    }

                    @media (max-width:575px) {
                        font-size: 15px;
                        line-height: 25px;
                        width: 110px;
                    }
                }

                td {
                    color: $text-color;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 30px;
                    width: calc(100% - 150px);
                    margin-left: 30px;
                    display: block;

                    @media (max-width:1399px) {
                        margin-left: 10px;
                        width: calc(100% - 110px);
                    }

                    @media (max-width:575px) {
                        font-size: 15px;
                        line-height: 25px;
                    }
                }
            }
        }

        li {
            color: #5A5A5A;
            font-size: 18px;
            font-weight: 400;
            line-height: 35px;
            display: flex;
            align-items: start;
            margin-bottom: 25px;

            @media (max-width:1199px) {
                margin-bottom: 10px;
            }

            @media (max-width:575px) {
                font-size: 15px;
                line-height: 25px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            img {
                margin-right: 10px;
                margin-top: 5px;

                @media (max-width:575px) {
                    margin-top: 0;
                }
            }
        }

        &.s2 {
            margin-bottom: 0;
        }
    }

    .AppointmentFrom {
        margin-top: 80px;

        @media (max-width:991px) {
            margin-top: 60px;
        }
    }
}

.experience_wrap {
    margin-top: 70px;


    @media (max-width:991px) {
        margin-top: 40px;
    }

    p {
        max-width: 1178px;
        color: $text-color;
        font-size: 18px;
        font-weight: 400;
        line-height: 35px;

        @media (max-width:575px) {
            font-size: 15px;
            line-height: 30px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .top_content {
        border-bottom: 1px solid $border-color-s2;
        padding-bottom: 40px;
        margin-bottom: 20px;

        @media (max-width:991px) {
            margin-bottom: 40px;
        }
    }


}

.skill_wrap {
    display: flex;
    align-items: start;
    justify-content: space-between;

    @media (max-width:991px) {
        flex-wrap: wrap;
    }

    .skill {
        width: 550px;

        @media (max-width:1199px) {
            max-width: 100%;
            width: 400px;
        }
    }

    .progress_item {
        margin-bottom: 30px;

        span {
            color: #767676;
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;
            margin-bottom: 10px;
            display: inline-block;
        }
    }

    .progres {
        border-radius: 4px;
        background: #EBEBEB;
        height: 8px;


        .progress-value {
            border-radius: 4px;
            background: $theme-primary-color;
            height: 8px;
            position: relative;

            span {
                color: #525252;
                font-size: 18px;
                font-weight: 400;
                line-height: 30px;
                position: absolute;
                margin-bottom: 0;
                right: -20px;
                width: 40px;
                height: 30px;
                top: -30px;
            }

        }
    }

    .achievements {
        max-width: 422px;
        margin-right: 80px;

        @media (max-width:1199px) {
            margin-right: 0;
        }

        ul {
            li {
                color: $text-color;
                font-size: 18px;
                font-weight: 400;
                line-height: 30px;
                margin-bottom: 25px;

                @media (max-width:767px) {
                    font-size: 15px;
                    margin-bottom: 10px;
                }

                span {
                    color: $heading-color;
                    font-weight: 500;
                }
            }
        }
    }



}