/*7. service_single  */

.service_content {

    img {
        width: 100%;
        border-radius: 30px;
        margin-bottom: 20px;
    }

    h2 {
        font-size: 70px;
        font-weight: 400;
        margin-bottom: 10px;
    }

    h3 {
        font-size: 60px;
        font-weight: 400;
        margin-bottom: 10px;
    }

    p {
        color: $text-color;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 40px;
    }

    ul {
        li {
            color: $text-color;
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;
            list-style: none;
            position: relative;
            margin-bottom: 20px;
            padding-left: 40px;

            &::before {
                position: absolute;
                left: 15px;
                top: 4px;
                transform: translateX(-50%);
                content: url(../../images/service-single/icon.svg);
                width: 25px;
                height: 25px;
                line-height: 25px;
                border-radius: 100px;
                background: #DCF1FF;
                text-align: center;
            }
        }
    }

    .other-service {
        margin-bottom: 60px;

        h3 {
            margin-top: -10px;
            margin-bottom: -20px;
        }
    }

    .service_card {
        padding: 30px 20px 10px;

        @media (max-width:1199px) {
            padding: 30px 10px 10px;
        }

        &::before {
            top: 30px;
            background: url(../../images/common-shape-service.png) no-repeat center;
        }

        .icon {
            width: 70px;
            height: 70px;
            margin-top: -50px;

            i {
                font-size: 33px;
                line-height: 84px;
            }

        }

        .content {
            h2 {
                font-size: 40px;
                line-height: 55px;
                margin-bottom: 0;
                margin-top: 25px;
            }

            p {
                margin-bottom: 10px;
                font-size: 17px;

                @media (max-width:1199px) {
                    font-size: 15px;
                }

            }


        }


    }

}

.cta_form_s2 {
    border-radius: 40px;
    background: #FFF;
    box-shadow: 0px 2px 8px 2px rgba(22, 14, 71, 0.10);
    padding: 50px 60px 70px;

    @media (max-width:767px) {
        padding: 50px 40px 70px;
    }

    @media (max-width:575px) {
        padding: 30px 20px 40px;
    }

    .title {
        margin-bottom: 50px;

        h3 {
            font-size: 60px;
            font-weight: 400;
            margin-bottom: 0;

            @media (max-width:575px) {
                font-size: 40px;
                line-height: 40px;
            }

        }

        p {
            color: $text-color;
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;
        }

        &.s2 {
            text-align: center;
        }
    }

    label {
        color: $heading-color;
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 13px;
        display: block;
    }

    .input-fild {
        border-radius: 15px;
        border: 1px solid #DDD;
        width: 100%;
        font-size: 20px;
        font-weight: 400;
        padding: 10px 20px;
        transition: all .3s ease-in-out;


        &:focus-visible {
            outline: 0px;
            border: 1px solid $light;
        }

    }

    .form_item {
        position: relative;
        margin-bottom: 35px;


    }

    textarea {
        max-width: 777px;
        max-height: 118px;
        width: 100%;
        height: 100%;
    }


    select {
        color: $text-color;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
        position: relative;
        display: inline-block;
        color: #a9a9a9;
        cursor: pointer;
        opacity: 1;
        -webkit-appearance: none;
        -o-appearance: none;
        appearance: none;
        -moz-appearance: none;
        background: transparent url(../../images/down-arrow.svg) no-repeat calc(100% - 15px) center;
        position: relative;
    }



    .theme-btn {
        padding: 10px 30px;
        margin-top: 33px;
    }

    .error {
        color: red;
        font-size: 16px;
        font-weight: 400;
        position: absolute;
        width: 100%;
        left: 10px;
        bottom: -25px;

        &.s2 {
            bottom: -55px;
        }
    }
}

.AppointmentFrom {
    margin-top: 30px;

    .cta_form_s2 textarea {
        max-width: 100%;
    }

    .cta_form_s2 .theme-btn {
        margin: 0 auto;
        margin-top: 33px;
        display: block;
    }
}

/* service_sidebar */

.service_sidebar {

    padding-left: 60px;

    @media (max-width:1199px) {
        padding-left: 10px;
    }

    @media (max-width:991px) {
        padding-left: 0px;
        max-width: 400px;
        margin-top: 50px;
    }

    .widget {
        margin-bottom: 50px;

        h2 {
            font-size: 50px;
            font-weight: 400;
            padding-bottom: 10px;
            position: relative;
            margin-top: -20px;
            margin-bottom: 25px;

            @media (max-width:1199px) {
                font-size: 40px;
            }

            &::before {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 4px;
                border-radius: 2px;
                background: #F3F3F3;
                content: '';
            }

            &::after {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 67px;
                height: 4px;
                border-radius: 2px;
                background: $light;
                content: '';
            }
        }
    }

    .search_widget {
        .searchForm {
            position: relative;
        }

        .fild {
            border-radius: 10px;
            background: #FFF;
            box-shadow: 0px 1px 10px 0px rgba(16, 45, 72, 0.15);
            width: 100%;
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;
            padding: 15px 30px;
            border: 1px solid transparent;
            transition: all .3s ease-in-out;
            padding-right: 70px;

            &:focus-visible {
                outline: 0px;
                border: 1px solid $light;
            }

        }

        button {
            position: absolute;
            right: 5px;
            top: 10%;
            width: 50px;
            height: 50px;
            line-height: 50px;
            border: 0;
            background: $light;
            font-size: 20px;
            color: $white;
            border-radius: 10px;
        }
    }

    .services_widget {
        ul {
            li {
                list-style: none;
                margin-bottom: 18px;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: $text-color;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 30px;
                    transition: all .3s ease-in-out;

                    span {
                        width: 30px;
                        height: 30px;
                        border-radius: 100px;
                        background: #EAEAEA;
                        text-align: center;
                        transition: all .3s ease-in-out;
                    }

                    &:hover {
                        color: $light;
                    }
                }
            }
        }
    }

    .newsletter_widget {

        span {
            color: $heading-color;
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;
            margin-bottom: 15px;
            display: block;
        }

        .fild {
            width: 100%;
            border-radius: 10px;
            background: $white;
            box-shadow: 0px 2px 10px 0px rgba(33, 25, 81, 0.15);
            color: $text-color;
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;
            padding: 12px 20px;
            border: 1px solid transparent;
            transition: all .3s ease-in-out;
            margin-bottom: 20px;

            &:focus-visible {
                outline: 0px;
                border: 1px solid $light;
            }
        }

        button {
            border-radius: 10px;
            background: $theme-primary-color;
            box-shadow: 0px 2px 10px 0px rgba(33, 25, 81, 0.15);
            color: $white;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            line-height: 30px;
            padding: 12px 20px;
            width: 100%;
            display: block;
            border: 0;
            margin-bottom: 20px;
        }
    }

    p {
        color: $text-color;
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 0;
    }

    .instagram_widget {
        margin-bottom: 0;

        ul {
            display: flex;
            flex-wrap: wrap;

            li {
                list-style: none;
                flex: 0 0 33.33%;
                max-width: 33.33%;
                margin-bottom: 8px;
                padding: 0px 5px;

                img {
                    width: 100%;
                    border-radius: 6px;
                }
            }
        }
    }
}