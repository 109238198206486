/*--------------------------------------------------------------
4. wpo-footer
--------------------------------------------------------------*/
.wpo-site-footer,.wpo-site-footer_s2 {
    background: url(../../images/footer-bg.png) center / cover no-repeat;
    position: relative;
    font-size: 15px;
    overflow: hidden;

    &::after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        background: #EBF7FF;
        z-index: -1;
    }


    ul {
        list-style: none;
    }

    p {
        color: $text-color;
        font-size: 17px;
    }

    li {
        color: $text-color;
    }

    .container {
        position: relative;
    }

    .wpo-upper-footer {
        padding: 100px 0;
        padding-top: 270px;
    }

    @media (max-width: 991px) {

        .wpo-upper-footer {
            padding: 50px 0 0;
        }
    }

    @media (max-width: 767px) {

        .wpo-upper-footer {
            padding: 60px 0 0;
        }
    }

    @media (max-width: 991px) {

        .wpo-upper-footer .col {
            min-height: 235px;
            margin-bottom: 70px;
        }
    }

    @media (max-width: 767px) {

        .wpo-upper-footer .col {
            min-height: auto;
            margin-bottom: 60px;
        }
    }

    .widget-title {
        margin-bottom: 30px;
    }

    @media (max-width: 767px) {

        .widget-title {
            margin-bottom: 20px;
        }
    }


    .widget-title {
        h3 {
            font-size: 45px;
            color: $heading-color;
            margin: 0;
            position: relative;
            font-family: $heading-font;
            font-weight: 400;
        }
    }

    .about-widget {
        .logo {
            max-width: 220px;
        }
    }

    .about-widget {
        p {
            margin-bottom: 0.8em;
            line-height: 1.9em;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .wpo-service-link-widget {
        padding-left: 70px;
    }

    .link-widget {
        overflow: hidden;


        @media screen and (min-width: 1200px) {
            padding-left: 75px;
        }

        @media (max-width: 1199px) {
            padding-left: 20px;
        }

        @media (max-width: 991px) {
            padding-left: 0;
        }

        @media (max-width: 767px) {
            max-width: 350px;
        }

        &.s2 {
            @media screen and (min-width: 1200px) {
                padding-left: 40px;
            }
        }

        ul {
            li {
                position: relative;

                a {
                    color: $text-color;
                    font-size: 18px;
                    font-weight: 400;
                    text-transform: capitalize;
                    position: relative;



                    &:hover {
                        color: $light;
                    }
                }
            }

            li+li {
                padding-top: 15px;
            }
        }
    }



    .social-widget {
        margin-top: 40px;

        ul {
            li {
                display: inline-block;
                text-align: center;
                margin-right: 10px;

                a {
                    font-size: 16px;
                    font-weight: 600;
                    color: $heading-color;
                    width: 40px;
                    height: 40px;
                    line-height: 45px;
                    text-align: center;
                    background: rgb(134, 188, 226, 0.15);
                    border-radius: 100px;
                    display: block;

                    &:hover {
                        color: $light;
                    }

                }
            }
        }
    }

    .contact-widget {

        ul {
            li {
                display: flex;
                align-items: start;

                i {
                    margin-right: 10px;
                    font-size: 18px;
                    color: $light;
                    padding-top: 5px;
                }

                color: $text-color;
                font-size: 18px;
                font-weight: 400;
                line-height: 32px;
                margin-bottom: 15px;
            }
        }
    }

    .wpo-lower-footer {

        position: relative;

        .row {
            padding: 25px 0;
            position: relative;
            border-top: 1px solid #D5DCE1;
        }

        .copyright {
            display: inline-block;
            margin: 0;
            text-align: left;
            color: #69A1C9;
            font-size: 18px;
            font-weight: 400;
            line-height: 32px;



            a {
                text-decoration: none;
                color: $heading-color;
            }

            @media (max-width: 991px) {
                float: none;
                display: block;
                text-align: center;
                margin-bottom: 20px;
            }
        }

        ul {
            text-align: right;

            @media (max-width: 991px) {
                text-align: center;
            }

            li {
                display: inline-block;
                margin-left: 15px;


                a {
                    color: #69A1C9;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 32px;
                }
            }
        }
    }


}


/* wpo-site-footer_s2 */
.wpo-site-footer_s2 {

    .wpo-upper-footer {
        padding: 100px 0;
    }

    @media (max-width: 991px) {

        .wpo-upper-footer {
            padding: 50px 0 0;
        }
    }

    @media (max-width: 767px) {

        .wpo-upper-footer {
            padding: 60px 0 0;
        }
    }
}